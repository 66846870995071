import { FC } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import LinkareerLogoWithName from 'svgIcons/LinkareerLogoWithName'
import PersonLoginDesktop from '../component/PersonLogin.desktop'

const ScreenLoginDesktop: FC = () => {
  return (
    <StyledWrapper>
      <Link href="/">
        <a aria-label="링커리어 메인으로 이동">
          <LinkareerLogoWithName />
        </a>
      </Link>
      <PersonLoginDesktop />
    </StyledWrapper>
  )
}

export default ScreenLoginDesktop

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
`
