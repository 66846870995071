import { FC } from 'react'
import styled, { css } from 'styled-components'

interface IFLoadingProps {
  className?: string
}

const Loading: FC<IFLoadingProps> = ({ className }) => {
  return (
    <StyledWrapper className={className}>
      <div className="loading-spinner" />
    </StyledWrapper>
  )
}

export default Loading

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes spinnerEase {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .loading-spinner {
      width: inherit;
      height: inherit;
      border: 3px solid ${theme.background.bg_1};
      border-top: 3px solid ${theme.color.primary_2};
      border-radius: 50%;
      animation: spinner 1.2s ease-in-out infinite;
    }
  `}
`
