import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const EmailLoginExpand: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="none" data-name="icon / 16 / dropdown">
        <path d="M0 0h16v16H0z" data-name="사각형 30409"></path>
        <path
          stroke="#333"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
          d="M3.17 6 8 10.831 12.831 6"
          data-name="패스 15894"
        ></path>
      </g>
    </svg>
  )
}

export default EmailLoginExpand
