import { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => {
  return {
    title: {
      position: 'absolute',
      left: 0,
      right: 0,
    },
  }
})

const MobileAppbarTitle: FC = ({ children }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.title} variant="h1" noWrap align="center">
      {children}
    </Typography>
  )
}

export default MobileAppbarTitle
