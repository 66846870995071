import { FC } from 'react'

interface IFCapsLockProps {
  color: string
  className: string
}

const CapsLock: FC<IFCapsLockProps> = ({ color, className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={color}
    >
      <path d="M12 8.40997L16.59 13L18 11.59L12 5.58997L6 11.59L7.41 13L12 8.40997ZM6 18H18V16H6V18Z" />
    </svg>
  )
}

export default CapsLock
