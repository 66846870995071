import IconButton from '@material-ui/core/IconButton'
import Router from 'next/router'
import ArrowBackIosIcon from 'svgIcons/ArrowBackIosIcon'

const GoBackButton = () => {
  return (
    <IconButton
      onClick={() => Router.back()}
      edge="start"
      color="inherit"
      aria-label="go back"
    >
      <ArrowBackIosIcon />
    </IconButton>
  )
}

export default GoBackButton
