import { FC } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import MobileAppbar, { GoBackButton } from 'components/MobileAppbar'
import LinkareerLogoWithName from 'svgIcons/LinkareerLogoWithName'
import PersonLoginMobile from '../component/PersonLogin.mobile'

const ScreenLoginMobile: FC = () => {
  return (
    <StyledWrapper>
      <MobileAppbar>
        <GoBackButton />
      </MobileAppbar>
      <Link href="/">
        <a aria-label="링커리어 메인으로 이동">
          <LinkareerLogoWithName />
        </a>
      </Link>
      <PersonLoginMobile />
    </StyledWrapper>
  )
}

export default ScreenLoginMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  background: white;
  min-height: calc(100vh - 129px);
`
