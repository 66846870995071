import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import URI from 'urijs'
import isUrl from '../module/isUrl'

const useReferrer = () => {
  const [referrer, setReferrer] = useState<string | null>(null)
  useEffect(() => {
    setReferrer(document.referrer)
  }, [])
  return referrer
}

interface IFUseGetLinkQueryStringParameter {
  protocol: string
  domain: string
}

const useGetLinkQueryString = ({
  domain,
  protocol,
}: IFUseGetLinkQueryStringParameter) => {
  const router = useRouter()
  const referrer = useReferrer()
  const next = router.query.next as string
  if (next) {
    if (isUrl(next)) {
      return `?next=${encodeURIComponent(next)}`
    }

    return `?next=${encodeURIComponent(protocol + '://' + domain + next)}`
  }

  if (
    referrer &&
    URI(referrer).domain() === URI(document.location.href).domain()
  ) {
    return `?next=${referrer}`
  }

  return ''
}

export default useGetLinkQueryString
