import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface IFCollapseProps {
  isShow: boolean
  children: ReactNode
}

const Collapse = ({ isShow, children }: IFCollapseProps) => {
  return <StyledWrapper isShow={isShow}>{children}</StyledWrapper>
}

export default Collapse

const StyledWrapper = styled.div<{ isShow: boolean }>`
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-duration: 300ms;
  ${(props) =>
    props.isShow
      ? css`
          opacity: 1;
          max-height: 1000px;
          overflow: visible;
        `
      : css`
          opacity: 0;
          max-height: 0px;
          overflow: hidden;
        `}
`
