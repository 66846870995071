import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const ArrowBackIosIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <desc>뒤로가기</desc>
      <path
        d="M11.67 3.86998L9.9 2.09998L0 12L9.9 21.9L11.67 20.13L3.54 12L11.67 3.86998Z"
        fill="black"
      />
    </svg>
  )
}

export default ArrowBackIosIcon
