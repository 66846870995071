import { FC } from 'react'
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'
import { useFormik } from 'formik'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'
import { UserType } from 'generated/graphql'
import Button from '@app.components/Button'
import AuthTextField from '@app.components/inputs/AuthTextField'
import useErrorSnackbar from 'hooks/useErrorSnackbar'
import useGetFormikFieldProps from 'hooks/useGetFormikFieldProps'
import useGoNextPage from 'hooks/useGoNextPage'

const SignupSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, '비밀번호는 최소 8글자 이상이어야 합니다.')
    .max(30, '비밀번호는 최대 30글자 이하이어야 합니다.')
    .required('비밀번호를 입력해주세요.'),
  usernameOrEmail: Yup.string().required('아이디를 입력해주세요.'),
})

export interface LoginPayload {
  user?: {
    type: UserType
    specupJoined?: boolean | null
  } | null
  signupToken?: string | null
}

interface EmailLoginFormProps {
  login: (
    email: string,
    password: string,
  ) => Promise<LoginPayload | null | undefined>
  isOrganizationLogin: boolean
  disabled?: boolean
}

const EmailLoginForm: FC<EmailLoginFormProps> = ({
  login,
  isOrganizationLogin,
  disabled = false,
}) => {
  const goNextPage = useGoNextPage()

  const { enqueueApolloError, enqueueErrorMessage } = useErrorSnackbar()

  const formik = useFormik({
    validationSchema: SignupSchema,
    initialValues: {
      usernameOrEmail: '',
      password: '',
    },

    onSubmit: async (values, actions) => {
      const { setSubmitting } = actions

      try {
        const payload = await login(values.usernameOrEmail, values.password)

        if (payload && payload.user) {
          if (payload.user.specupJoined) {
            goNextPage()
          } else {
            if (payload.signupToken) {
              window.location.href = `/signup?token=${payload.signupToken}`
            } else {
              // TODO: somethin went wrong :(
              enqueueErrorMessage('다른 이메일 계정으로 로그인해주세요.')
            }
          }
        } else {
          enqueueErrorMessage('로그인 정보를 정확히 입력해주세요.')
        }
      } catch (error) {
        enqueueApolloError(error)
        setSubmitting(true)
      }
    },
  })

  const getFormikFieldProps = useGetFormikFieldProps(formik)
  const { handleSubmit, isSubmitting } = formik

  return (
    <StyledWrapper onSubmit={handleSubmit} noValidate>
      <CustomAuthTextField
        id={`email-login-${isOrganizationLogin ? 'org-' : ''}email`}
        placeholder="아이디를 입력하세요"
        name="usernameOrEmail"
        disabled={isSubmitting || disabled}
        {...getFormikFieldProps('usernameOrEmail')}
      />

      <ReactIsCapsLockActive>
        {(active: boolean) => (
          <CustomAuthTextField
            id={`email-login-${isOrganizationLogin ? 'org-' : ''}password`}
            placeholder="비밀번호를 입력하세요"
            name="password"
            type="password"
            disabled={isSubmitting || disabled}
            isCapsLock={active}
            {...getFormikFieldProps('password')}
          />
        )}
      </ReactIsCapsLockActive>

      <Button
        data-testid={`email-login-${
          isOrganizationLogin ? 'org-' : ''
        }submit-button`}
        className="submit-button"
        disabled={isSubmitting || disabled}
        type="submit"
        color="primary"
      >
        로그인
      </Button>
    </StyledWrapper>
  )
}

export default EmailLoginForm

const StyledWrapper = styled.form`
  ${({ theme }) => css`
    padding: 7px 20px 20px;
    .submit-button {
      width: 100%;
      height: 50px;
      margin-top: ${theme.isMobile ? '5px' : '8px'};
      font-size: 16px;
    }
  `}
`
const CustomAuthTextField = styled(AuthTextField)`
  width: 100%;
`
