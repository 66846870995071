import { FormikProps, FormikValues } from 'formik'
import get from 'lodash/get'

interface PropsOptions {
  errorMessage?: string | null
}

function useGetFormikFieldProps<Values extends FormikValues = FormikValues>(
  formikProps: Pick<
    FormikProps<Values>,
    'values' | 'touched' | 'errors' | 'handleBlur' | 'handleChange'
  >,
) {
  return (fieldName: string, options?: PropsOptions) => {
    const { errorMessage: errorMessageOption } = options || {}
    const value: string = get(formikProps.values, fieldName, '')
    const touched: boolean = get(
      formikProps.touched,
      fieldName,
      false,
    ) as boolean

    let errorMessage: string | undefined = get(
      formikProps.errors,
      fieldName,
      undefined,
    ) as string | undefined
    if (errorMessageOption) {
      errorMessage = errorMessageOption
    }

    const error = !!errorMessage

    return {
      onChange: formikProps.handleChange,
      onBlur: formikProps.handleBlur,
      value,
      helperText: touched ? errorMessage : undefined,
      error: touched ? error : false,
    }
  }
}

export default useGetFormikFieldProps
