import { useLocalStorage } from 'react-use'
import { ButtonProps } from '@app.components/Button/Button'
import { IS_IOS_NATIVE } from 'constants/storeKeys'
import AppleLogo from 'svgIcons/AppleLogo'
import KakaoLogo from 'svgIcons/KakaoLogo'
import NaverLogo from 'svgIcons/NaverLogo'

export type TLinkType = 'login' | 'signup'
interface IFuseSocialLoginDataListParameter {
  url: string
  linkQuery: string
  linkType: TLinkType
}

export interface IFHrefButtonProps extends ButtonProps {
  href: string
}

const useSocialLoginDataList = ({
  linkQuery,
  url,
  linkType,
}: IFuseSocialLoginDataListParameter) => {
  const [isIOSNative] = useLocalStorage(IS_IOS_NATIVE, false)
  const _linkQuery = linkType === 'login' ? linkQuery : ''

  const socialLoginDataList: IFHrefButtonProps[] = [
    {
      href: `${url}/auth/naver${_linkQuery}`,
      className: 'naver-login-button',
      startIcon: <NaverLogo className="logo-icon" whiteLogo />,
      children: '네이버로 로그인',
    },
    {
      href: `${url}/auth/kakao${_linkQuery}`,
      className: 'kakao-login-button',
      startIcon: <KakaoLogo className="logo-icon" />,
      children: '카카오톡으로 로그인',
    },
    {
      href: isIOSNative
        ? `linkareer://apple-login${_linkQuery}`
        : `${url}/auth/apple${_linkQuery}`,
      startIcon: <AppleLogo fillColor="#fff" className="apple-logo-icon" />,
      className: 'apple-login-button',
      children: 'Apple로 로그인',
    },
  ]

  return socialLoginDataList
}

export default useSocialLoginDataList
