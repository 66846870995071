import { FC } from 'react'
import { Toolbar } from '@material-ui/core'
import AppBar, { AppBarProps } from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: 'transparent',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      zIndex: 1000,
    },
    divider: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }
})

type MobileAppbarProps = AppBarProps & {
  divider?: boolean
}
const MobileAppbar: FC<MobileAppbarProps> = ({
  children,
  className,
  divider = false,
  ...props
}) => {
  const classes = useStyles()

  return (
    <AppBar
      data-testid="mobile-appbar-root"
      elevation={0}
      position="static"
      className={clsx(
        classes.root,
        {
          [classes.divider]: divider,
        },
        className,
      )}
      color="inherit"
      {...props}
    >
      <Toolbar>{children}</Toolbar>
    </AppBar>
  )
}

export default MobileAppbar
