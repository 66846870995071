import { FC, useState } from 'react'
import { useDomain } from 'context'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { useGqlLoginPersonMutation } from 'generated/graphql'
import Button from '@app.components/Button'
import Collapse from '@app.feature/login/component/Collapse'
import EmailLoginForm from '@app.feature/login/component/EmailLoginForm'
import useGetLinkQueryString from '@app.feature/login/hooks/useGetLinkQueryString'
import useSocialLoginDataList, {
  IFHrefButtonProps,
  TLinkType,
} from '@app.feature/login/hooks/useSocialLoginDataList'
import {
  APPLE_COLOR,
  KAKAO_BG_COLOR,
  KAKAO_TEXT_COLOR,
  NAVER_COLOR,
} from 'style/colors'
import EmailIcon from 'svgIcons/Email'
import EmailLoginExpand from 'svgIcons/EmailLoginExpand'

interface IFSocialLoginSectionProps {
  linkType: TLinkType
}

const SocialLoginSection: FC<IFSocialLoginSectionProps> = ({ linkType }) => {
  const { protocol, linkareer, linkareerAPIBaseURL } = useDomain()
  const linkQuery = useGetLinkQueryString({ domain: linkareer, protocol })
  const [isSNSToggleOpen, setIsSNSToggelOpen] = useState(false)
  const [showEmailLogin, setShowEmailLogin] = useState<boolean>(false)

  const [mutateLoginPerson] = useGqlLoginPersonMutation()
  const socialLoginDataList = useSocialLoginDataList({
    url: linkareerAPIBaseURL,
    linkQuery: linkQuery,
    linkType,
  })

  let socialLoginDataListNaver: IFHrefButtonProps = {} as IFHrefButtonProps
  const socialLoginDataListWithoutNaver: IFHrefButtonProps[] = []

  socialLoginDataList.forEach((loginData) => {
    if (loginData.className === 'naver-login-button') {
      socialLoginDataListNaver = loginData
    } else {
      socialLoginDataListWithoutNaver.push(loginData)
    }
  })
  const login = async (email: string, password: string) => {
    const response = await mutateLoginPerson({
      variables: { email, password },
    })
    return response.data?.loginWithTimespread
  }
  const handleSNSToggle = () => {
    setIsSNSToggelOpen((prev) => !prev)
  }
  const handleEmailToggle = () => {
    setShowEmailLogin((prev) => !prev)
  }

  return (
    <StyledWrapper>
      {socialLoginDataListWithoutNaver.map(({ href, ...item }, index) => {
        return (
          <Link href={href} key={index}>
            <Button {...item} />
          </Link>
        )
      })}
      <div className="sns-toggle-wrapper">
        <button className="sns-toggle-button" onClick={handleSNSToggle}>
          다른 방법으로 로그인
          <EmailLoginExpand
            className={`toggle-arrow ${
              isSNSToggleOpen ? 'toggle-expanded' : ''
            }`}
          />
        </button>
        <Collapse isShow={isSNSToggleOpen}>
          <div className="opened-toggle">
            <Link href={socialLoginDataListNaver.href}>
              <Button {...socialLoginDataListNaver} />
            </Link>
            <div className="email-toggle-wrapper">
              <Button
                onClick={handleEmailToggle}
                className="email-button"
                startIcon={<EmailIcon className="email-icon" />}
                endIcon={
                  <EmailLoginExpand
                    width={17}
                    height={17}
                    className={showEmailLogin ? 'toggle-expanded' : ''}
                  />
                }
              >
                <span>이메일로 로그인</span>
              </Button>
              <Collapse isShow={showEmailLogin}>
                <EmailLoginForm
                  disabled={!showEmailLogin}
                  login={login}
                  isOrganizationLogin={false}
                />
              </Collapse>
            </div>
          </div>
        </Collapse>
      </div>
    </StyledWrapper>
  )
}

export default SocialLoginSection

export const buttonStyle = css`
  width: 100%;
  border-radius: 8px;
  font-size: ${({ theme }) => (theme.isMobile ? '17px' : '20px')};
  padding: 13px 0px;
  font-weight: 500;
  position: relative;
  text-rendering: auto;
  height: ${({ theme }) => (theme.isMobile ? '50px' : '56px')};
`

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    overflow: hidden;

    .apple-start-icon {
      margin-left: 0;
      position: absolute;
      left: 4px;
    }

    .logo-icon {
      width: 20px;
      height: 20px;
    }

    .apple-logo-icon {
      width: 52px;
      height: 52px;
      position: absolute;
      left: -16px;
      top: -26px;
    }

    .start-icon {
      margin-left: 0;
      position: absolute;
      left: 20px;
    }

    .kakao-login-button {
      ${buttonStyle};
      background-color: ${KAKAO_BG_COLOR};
      color: ${KAKAO_TEXT_COLOR};
      &:hover {
        background-color: #ccb90e;
      }
    }

    .apple-login-button {
      ${buttonStyle}
      background-color: ${APPLE_COLOR};
      color: #ffffff;
      padding: 13px 0px;
      text-transform: initial;

      &:hover {
        background-color: ${APPLE_COLOR};
      }
    }

    .sns-toggle-wrapper {
      background: ${theme.background.bg_3};
      display: flex;
      justify-content: center;
      flex-direction: column;
      ${buttonStyle}
      height: auto;
    }
    .sns-toggle-button {
      background: none;
      border: none;
      display: flex;
      justify-content: center;
      cursor: pointer;
      font-size: ${theme.isMobile ? '14px' : '16px'};
      letter-spacing: -0.64px;
      text-align: center;
      align-items: center;
      line-height: normal;
      color: #333;
      .toggle-arrow {
        margin-left: 4px;
      }
    }
    .opened-toggle {
      padding: 20px;
      padding-top: ${theme.isMobile && '12px'};
    }
    .toggle-expanded {
      transform: rotate(180deg);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .naver-login-button {
      ${buttonStyle}
      background-color: ${NAVER_COLOR};
      color: #ffffff;
      &:hover {
        background-color: #18a000;
      }
    }
    .email-toggle-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      ${buttonStyle}
      background: white;
      padding: 0;
      height: auto;
      border: 1px solid ${theme.line.line_1};
      margin-top: 15px;
    }
    .email-icon {
      path {
        fill: #01a0ff;
      }
    }
    .email-button {
      ${buttonStyle}
      background-color: white;
      color: #333333;
    }
  `}
`
