import Link from 'next/link'
import styled from 'styled-components'
import SocialLoginSection, { buttonStyle } from 'components/SocialLoginSection'

const PersonLoginMobile = () => {
  return (
    <StyledWrapper>
      <SocialLoginSection linkType="login" />
      <div className="sign-up-link-wrapper">
        <Link color="inherit" href="/signup/select" className="sign-up-typo">
          링커리어 회원가입
        </Link>
      </div>
    </StyledWrapper>
  )
}

export default PersonLoginMobile

const StyledWrapper = styled.div`
  width: 350px;
  overflow: hidden;
  margin-top: 40px;

  .start-icon {
    margin-left: 0;
    position: absolute;
    left: 20px;
  }

  .logo-icon,
  .email-icon {
    width: 20px;
    height: 20px;
  }

  .divider {
    display: flex;
    width: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.38);
    margin: 10px 0px 20px 0px;
  }

  .divider-border {
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.38);
    width: 100%;
  }

  .divider-content {
    min-width: 74px;
    text-align: center;
    padding: 0px 20px;
  }

  .biz-login {
    ${buttonStyle}
    margin-bottom:15px;
    width: 100%;
    border-color: #01a0ff;
    color: #01a0ff;
  }

  .biz-login-wrapper {
    display: flex;
    justify-content: center;
  }

  .sign-up-link-wrapper {
    display: flex;
    justify-content: center;
    > a {
      margin: 35px 0px 20px;
      font-size: 16px;
      font-weight: normal;
      text-align: center;
      color: #bbbbbb;
      text-decoration: underline;
    }
  }
`
