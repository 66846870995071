import { useRouter } from 'next/router'

function useGoNextPage(redirectUrl?: string) {
  const router = useRouter()

  function goNextPage() {
    window.location.href = redirectUrl
      ? redirectUrl
      : (router.query.next as string)
  }

  return goNextPage
}

export default useGoNextPage
