import { useDomain } from 'context'
import Link from 'next/link'
import styled from 'styled-components'
import Button from '@app.components/Button'
import useGetLinkQueryString from '@app.feature/login/hooks/useGetLinkQueryString'
import SocialLoginSection, { buttonStyle } from 'components/SocialLoginSection'
import { LINKAREER_BIZ_BASE_URL } from 'utils/config'

const PersonLoginDesktop = () => {
  const { protocol, linkareer } = useDomain()

  const linkQuery = useGetLinkQueryString({ domain: linkareer, protocol })

  const bizLoginUrl = `https://${LINKAREER_BIZ_BASE_URL}/login${linkQuery}`

  return (
    <StyledWrapper>
      <SocialLoginSection linkType="login" />

      <div className="divider">
        <div className="divider-border" />
        <span className="divider-content">또는</span>
        <div className="divider-border" />
      </div>
      <div className="biz-login-wrapper">
        <a href={bizLoginUrl}>
          <Button variant="outlined" className="biz-login">
            기업회원 로그인
          </Button>
        </a>
      </div>
      <div className="sign-up-link-wrapper">
        <Link href="/signup/select">
          <a className="sign-up-typo">링커리어 회원가입</a>
        </Link>
      </div>
    </StyledWrapper>
  )
}

export default PersonLoginDesktop

const StyledWrapper = styled.div`
  width: 450px;
  overflow-x: hidden;
  margin-top: 60px;

  .divider {
    display: flex;
    width: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.38);
    margin: 10px 0px 20px 0px;
  }

  .divider-border {
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.38);
    width: 100%;
  }

  .logo-icon,
  .email-icon {
    width: 20px;
    height: 20px;
  }

  .start-icon {
    margin-left: 0;
    position: absolute;
    left: 20px;
  }

  .divider-content {
    min-width: 74px;
    text-align: center;
    padding: 0px 20px;
  }

  .biz-login {
    ${buttonStyle}
    margin-bottom:15px;
    width: 100%;
    border-color: #01a0ff;
    color: #01a0ff;
  }

  .biz-login-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    a {
      width: 100%;
    }
  }

  .expanded {
    transform: rotate(180deg);
  }

  .sign-up-link-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 35;
  }

  .sign-up-typo {
    margin: 35px 0px 20px;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    color: #bbbbbb;
    text-decoration: underline;
  }
`
