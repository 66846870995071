import { FC } from 'react'
import { useUA } from 'context'
import { NextSeo } from 'next-seo'
import { withRouter } from 'next/router'
import ScreenLoginDesktop from '@app.feature/login/desktop/screen/ScreenLogin.desktop'
import ScreenLoginMobile from '@app.feature/login/mobile/screen/ScreenLogin.mobile'
import PageLayoutDesktop from 'app.layout/desktop/PageLayout.desktop'
import PageLayoutMobile from 'app.layout/mobile/PageLayout.mobile'
import useCanonical from 'hooks/useCanonical'

const TITLE = '로그인 - 링커리어'

const LoginPage: FC = () => {
  const { isMobile } = useUA()
  const canonical = useCanonical('/login')

  return (
    <>
      <NextSeo title={TITLE} canonical={canonical} />
      {isMobile && (
        <PageLayoutMobile renderOptions={{ Footer: true }}>
          <ScreenLoginMobile />
        </PageLayoutMobile>
      )}
      {!isMobile && (
        <PageLayoutDesktop renderOptions={{ Footer: true }}>
          <ScreenLoginDesktop />
        </PageLayoutDesktop>
      )}
    </>
  )
}

export default withRouter(LoginPage)
