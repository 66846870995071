import {
  ChangeEvent,
  FC,
  FocusEventHandler,
  useEffect,
  useState,
  FocusEvent,
} from 'react'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import Loading from '@app.components/Loading/Loading'
import CapsLock from 'svgIcons/CapsLock'
import CheckIcon from 'svgIcons/Check'

export interface IFAuthTextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
  onDebounceChange?(value: string): void
  isCapsLock?: boolean
  isAsyncValid?: boolean
  isAsyncValidating?: boolean
  debounceDelayMS?: number
  error: boolean
  onBlur: FocusEventHandler<HTMLInputElement>
  InputProps?: any
  helperText?: Exclude<React.ReactNode, boolean | null | undefined>
}

const AuthTextField: FC<IFAuthTextFieldProps> = ({
  helperText,
  isCapsLock,
  className,
  InputProps,
  isAsyncValid = false,
  isAsyncValidating = false,
  onDebounceChange,
  error = false,
  debounceDelayMS = 0,
  onChange,
  onBlur,
  ...props
}) => {
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false)
  const [debouncedCallback, cancel] = useDebouncedCallback((value) => {
    if (onDebounceChange) {
      onDebounceChange(value)
    }
  }, debounceDelayMS)

  useEffect(() => {
    if (!debounceDelayMS) {
      cancel()
    }
  }, [debounceDelayMS, cancel])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)
    }

    debouncedCallback(event.target.value)
  }

  const handleFocus = () => {
    setIsInputFocus(true)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur(event)
    setIsInputFocus(false)
  }

  const asyncValidating = <Loading className="input-icon" />

  const asyncValidatingPass = <CheckIcon className="input-icon" />

  let endAdornment = <></>
  if (isAsyncValidating) {
    endAdornment = asyncValidating
  } else if (isAsyncValid && !error) {
    endAdornment = asyncValidatingPass
  } else if (isCapsLock) {
    let color = 'black'
    if (helperText) {
      color = '#ef2929'
    } else if (!helperText && isInputFocus) {
      color = '#01a0ff'
    } else if (!helperText && !isInputFocus) {
      color = 'black'
    }
    endAdornment = <CapsLock className="caps-lock-icon" color={color} />
  } else {
    endAdornment = InputProps?.endAdornment
  }

  return (
    <StyledWrapper className={className}>
      <input
        onFocus={handleFocus}
        onChange={handleChange}
        type="text"
        className={className}
        data-require={require}
        data-error={!!helperText}
        onBlur={handleBlur}
        {...props}
      />
      {endAdornment}
      <div className="label-box">
        {helperText && <p className="error">{helperText}</p>}
      </div>
    </StyledWrapper>
  )
}

export default AuthTextField

const StyledWrapper = styled.div`
  position: relative;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 12px;

  .label-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .input-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    > path {
      color: #01a0ff;
    }
  }

  .caps-lock-icon {
    position: absolute;
    right: 15px;
  }

  .required-label {
    width: 5px;
    height: 5px;
    margin-right: 5px;
    border-radius: 100px;
    background-color: #01a0ff;
  }

  .error {
    font-size: 12px;
    color: #ef2929;
  }

  .label {
    display: flex;
    align-items: center;
    color: #999999;
    font-size: 12px;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: -0.6px;
    margin: 0px;
  }

  input {
    width: 100%;
    height: 50px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 14px;
    flex-shrink: 1;
    background-color: white;
    border: 1px solid #cccccc;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 16px;

    &[disabled] {
      color: #999999;
      background-color: #eeeeee;
    }

    &[data-error='false'] {
      &:focus {
        border: 1px solid #01a0ff;
        color: #01a0ff;
        &::placeholder {
          color: #01a0ff;
        }
      }
    }
  }
`
